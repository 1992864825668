import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ALL_ROUTES } from './core/routes/all-routes';
import { ProjectLayoutComponent } from './core/layout/project-layout/project-layout.component';
import { BlankLayoutComponent } from './core/layout/blank-layout/blank-layout.component';


const routes: Routes = [
  {
    path: 'login',
    component: BlankLayoutComponent,
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: '',
    component: ProjectLayoutComponent,
    children: ALL_ROUTES
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration:'enabled', anchorScrolling: 'enabled',scrollOffset: [0, 0]})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
