import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { ProjectLayoutComponent } from './layout/project-layout/project-layout.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BlankLayoutComponent, ProjectLayoutComponent, HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class CoreModule { }
