<nav class="navbar navbar-expand-md navbar-light shadow">
    <div class="container">
        <a class="navbar-brand p-0" href="#"><img src="../../../../assets/images/logo-png.png" alt="logo"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Features</a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" [routerLink]="[ '/features' ]"><img src="../../../../assets/images/dropmenu1.svg" class="mr-2"> Web Portal</a>
                        <a class="dropdown-item" href="#"><img src="../../../../assets/images/ma-menu.svg" class="mr-2"> Mobile App</a>

                    </div>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Our Team</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[ '/about-us' ]">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[ '/blog' ]">Blog</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[ '/contact' ]">Contact Us</a>
                </li>

            </ul>
        </div>
    </div>
</nav>