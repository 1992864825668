import { Routes } from '@angular/router';

// based on the module name in data - different footers will be rendered

export const ALL_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../../home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'about-us',
        loadChildren: () => import('../../about-us/about-us.module').then(m => m.AboutUsModule)
    },
    {
        path: 'blog',
        loadChildren: () => import('../../blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'features',
        loadChildren: () => import('../../features/features.module').then(m => m.FeaturesModule)
    }
];
