<footer class="footer position-relative">
    <img src="../../../../assets/images/ft-bubble.svg" class="bubble-ft">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="row">
                    <div class="col-6 col-md-3 mb-5">
                        <h5>Company</h5>
                        <ul class="ft-links">
                            <li><a [routerLink]="[ '/about-us' ]">About us</a> </li>
                            <li><a [routerLink]="[ '/contact' ]">Contact Us</a> </li>
                            <!-- <li><a href="#">Our Team</a> </li> -->
                            <li><a [routerLink]="[ '/blog' ]">Blog</a> </li>

                        </ul>
                    </div>
                    <div class="col-6 col-md-3 mb-5">
                        <h5>Legal</h5>
                        <ul class="ft-links">
                            <li><a [routerLink]="[ '/terms-and-conditions' ]">Terms & Conditions</a> </li>
                            <li><a [routerLink]="[ '/privacy-policy' ]">Privacy Policy</a> </li>
                            <li><a href="#">Disclaimer </a> </li>


                        </ul>
                    </div>
                    <div class="col-6 col-md-3 mb-5">
                        <h5>More</h5>
                        <ul class="ft-links">
                            <li><a href="#">Help & Support</a> </li>
                            <li><a [routerLink]="[ '/faq' ]">FAQs</a> </li>


                        </ul>
                    </div>
                    <div class="col-6 col-md-3 mb-5">
                        <h5>Quick Link</h5>
                        <ul class="ft-links">
                            <li><a [routerLink]="[ '/features' ]">Web Portal</a> </li>
                            <li><a href="#">Mobile App</a> </li>


                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="text-center text-md-right mb-5">
                    <img src="../../../../assets/images/ft-logo.png">
                </div>
                <div class="media mb-5">
                    <img src="../../../../assets/images/location.svg" class="mr-3">
                    <div class="media-body">
                        <h6 class="text-uppercase">Corporate Office Address</h6>
                        <p>Plot no 156, Scheme no 78 -<br>
                            Part 2</p>
                    </div>
                </div>
                <div class="media mb-5">
                    <img src="../../../../assets/images/location.svg" class="mr-3">
                    <div class="media-body">
                        <h6 class="text-uppercase">Branch Address</h6>
                        <p>Plot no 156, Scheme no 78 -<br>
                            Part 2</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft-bottom pt-4">
            <div class="row">
                <div class="col-md-6 mb-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    <p class="mb-0">Copyrights © 2020 A-Institute</p>
                </div>
                <div class="col-md-6 mb-5 text-md-right text-center">
                    <img src="../../../../assets/images/norton.png">
                </div>
            </div>
        </div>
    </div>
</footer>
